.popular-slider {
  gap: 12px;

  @media (min-width: 640px) {
    flex-wrap: wrap;
    gap: 16px 12px;
  }
  .popular-prefecture-item-boxes {
    flex-direction: column;
    gap: 0;
    height: 264px;
    flex-wrap: wrap;
    scroll-snap-type: x mandatory;

    @media (min-width: 640px) {
      flex-direction: row;
      gap: 12px;
      height: auto;
      justify-content: center;
      align-items: center;
    }

    .prefecture-box {
      padding-left: 16px;
      scroll-snap-align: start;

      &:nth-child(2n + 1) {
        margin-bottom: 16px;
      }

      &:nth-last-child(-n + 2) {
        padding-right: 16px;
      }

      @media (min-width: 640px) {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;

        &:nth-child(2n + 1) {
          margin-bottom: 0;
        }

        &:nth-last-child(-n + 2) {
          padding-right: 0;
        }
      }

      .prefecture-image {
        scroll-snap-align: none;
        border-radius: 10px;
        margin-bottom: 8px;
        background: #a0aec0;
        width: 96px;
        height: 96px;
      }
    }
  }

  .popular-job-category-item-boxes {
    flex-direction: column;
    gap: 0;
    height: 160px;
    flex-wrap: wrap;
    scroll-snap-type: x mandatory;

    @media (min-width: 640px) {
      flex-direction: row;
      gap: 12px;
      height: auto;
      justify-content: center;
      align-items: center;
    }

    .job-category-box {
      scroll-snap-align: start;

      &:first-child {
        padding-left: 16px;
      }

      &:not(:first-child) {
        margin-left: 12px;
      }

      &:nth-child(2n + 1) {
        margin-bottom: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }

      @media (min-width: 640px) {
        margin-left: 0;
        padding-right: 0;
        margin-bottom: 0;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        &:not(:first-child) {
          margin-left: 0px;
        }

        &:nth-child(2n + 1) {
          margin-bottom: 0;
        }
      }

      .job-category-image {
        scroll-snap-align: none;
        border-radius: 10px;
        margin-bottom: 8px;
        background: #a0aec0;
        width: 120px;
        height: 160px;
        position: relative;
      }
    }
  }

  .popular-particular-condition-item-boxes {
    flex-direction: column;
    gap: 0;
    height: 232px;
    flex-wrap: wrap;
    scroll-snap-type: x mandatory;

    @media (min-width: 640px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 12px;
      height: auto;
      margin: 0 auto;
    }

    .particular-condition-box {
      padding-left: 16px;
      scroll-snap-align: start;

      &:nth-child(2n + 1) {
        margin-bottom: 16px;
      }

      &:nth-last-child(-n + 2) {
        padding-right: 16px;
      }

      @media (min-width: 640px) {
        padding-left: 0;
        padding-right: 0;

        &:nth-child(2n + 1) {
          margin-bottom: 0;
        }

        &:nth-last-child(-n + 2) {
          padding-right: 0;
        }
      }

      .particular-condition-image {
        scroll-snap-align: none;
        border-radius: 10px;
        margin-bottom: 8px;
        background: #a0aec0;
        width: 120px;
        height: 80px;
      }
    }
  }

  .popular-liked-scout-item-boxes {
    gap: 8px;
    height: auto;
    scroll-snap-type: x mandatory;

    @media (min-width: 640px) {
      flex-direction: row;
      gap: 12px;
      height: auto;
      justify-content: center;
      align-items: center;
    }

    .liked-scout-box {
      min-width: 320px;
      scroll-snap-align: start;

      // &:first-child {
      //   padding-left: 16px;
      // }

      // &:not(:first-child) {
      //   margin-left: 12px;
      // }

      // &:last-child {
      //   padding-right: 16px;
      // }

      @media (min-width: 640px) {
        margin-left: 0;
        padding-right: 0;
        margin-bottom: 0;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        &:not(:first-child) {
          margin-left: 0px;
        }
      }
    }
  }

  .explain-item-boxes {
    flex-direction: column;
    gap: 8px;
    height: 310px;
    flex-wrap: wrap;
    scroll-snap-type: x mandatory;

    @media (min-width: 640px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
      height: auto;
      margin: 0 auto;
      padding: 0;
    }

    .explain-item-box-wrapper {
      &:last-child {
        margin-right: 16px;
      }

      @media (min-width: 640px) {
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .explain-item-box {
      display: flex;
      width: 320px;
      gap: 8px;
      padding: 8px;
      border: 1px solid #e2e8f0;
      border-radius: 8px;

      @media (min-width: 640px) {
        width: auto;
      }

      .explain-image {
        scroll-snap-align: none;
        border-radius: 4px;
        background: #a0aec0;
        width: 120px;
        height: 80px;
      }
    }
  }
}
